import styles from "./Footer.module.css";
import { FunctionComponent, useCallback } from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleContact = useCallback(() => {
    navigate("/contact");
  }, []);
  return (
    <footer className={styles.bottomFrame}>
      <div className={styles.iconFrame}>
        <img
          className={styles.icRemotoIcon}
          alt=""
          src="/icon.png"
        />
        <div className={styles.text}>
          <div className={styles.remotopro}>© 2025 Remote Studio Inc.</div>
        </div>
      </div>
      <div className={styles.footerLinks}>
        <div className={styles.links}>

          {/* UNCOMMENT THIS WHEN NEEDED */}
        {/* <a className = {styles.text3} href = "/help-center"> Help Center</a> | */}

        <a className = {styles.text3} href = "/resources"> Resources</a>
          |
          <a className = {styles.text3} href = "/careers"> Careers</a>
          |
          <a className = {styles.text3} href = "/legal"> Legal</a>
          |
          <a className = {styles.text3} href = "https://www.linkedin.com/company/remotopro"> LinkedIn</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
